export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/invitation": [7],
		"/lgpd": [8],
		"/lgpd/mentor": [9],
		"/lgpd/privacy": [10],
		"/lgpd/terms": [11],
		"/manager": [12,[2]],
		"/manager/actions": [20,[2]],
		"/manager/dashboard": [21,[2]],
		"/manager/groups": [22,[2]],
		"/manager/methodology": [23,[2]],
		"/manager/methodology/[sectionId]/[outputId]": [24,[2]],
		"/manager/methodology/[sectionId]/[outputId]/[toolId]": [25,[2]],
		"/manager/teams": [26,[2]],
		"/manager/users": [27,[2]],
		"/manager/[group]": [13,[2]],
		"/manager/[group]/[project]/actions": [14,[2,3]],
		"/manager/[group]/[project]/manage/edit": [15,[2,3]],
		"/manager/[group]/[project]/manage/progress": [16,[2,3]],
		"/manager/[group]/[project]/monitoring": [17,[2,3]],
		"/manager/[group]/[project]/resume": [18,[2,3]],
		"/manager/[group]/[project]/team": [19,[2,3]],
		"/methodology": [28],
		"/profile": [29],
		"/project": [30],
		"/requested-mentorings": [31],
		"/share-project": [32],
		"/teams": [33],
		"/videos": [34],
		"/[section]/[output]": [5],
		"/[section]/[output]/[tool]": [6]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';